import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Overview/index.ts";
import { SiPostgresql as PostgreSQLIcon, SiMysql as MySQLIcon } from 'react-icons/si';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LinkGroupContainer = makeShortcode("LinkGroupContainer");
const LinkGroup = makeShortcode("LinkGroup");
const LinkGroupCard = makeShortcode("LinkGroupCard");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`Learn how to import GeoIP2 and GeoLite2 CSV format databases into popular SQL
database platforms.`}</p>
    <LinkGroupContainer mdxType="LinkGroupContainer">
      <LinkGroup heading="Guides" mdxType="LinkGroup">
        <LinkGroupCard description="Import databases to PostgreSQL." heading="PostgreSQL" icon={PostgreSQLIcon} to="/geoip/importing-databases/postgresql" mdxType="LinkGroupCard" />
        <LinkGroupCard description="Import databases to MySQL." heading="MySQL" icon={MySQLIcon} to="/geoip/importing-databases/mysql" mdxType="LinkGroupCard" />
      </LinkGroup>
    </LinkGroupContainer>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      